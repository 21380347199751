
/* General spooky Dracula-theme styles */
body {
    background: linear-gradient(120deg, #1e2c46, #243b55); /* Dark spooky background */
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.1); /* Transparent light background */
    backdrop-filter: blur(8px); /* Blurred effect for background visibility */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6); /* Subtle shadow for depth */
}
  
  /* Top container for Contact Us button and Audio Player */
  .top-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* max-width: 400px; */
    margin-bottom: 10px;
    padding: 10px;
  }
  
  /* Button styling */
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 21px;
    cursor: pointer;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s, transform 0.2s;
  }
  .all-paths-button{
    padding: 10px 20px;
  }
  
  .contact-button{
    background-color: dodgerblue;
    border-radius: 7px;
    margin-left: 27%;
  }
  
  .privacy-policy-button,
  .option-button,
  .restart-button,
  .all-paths-button,
  .next-button,
  .close-button {
    background-color: #d86868;
  }
  
  
  
  .all-paths-button {
    background-color: #d86868;
    margin-top: 20px;
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 21px;
    cursor: pointer;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .all-paths-button:hover {
    background-color: #050505;
    color: white;
    transform: scale(1.05);
  }
  
  
  @media (min-width: 769px) {
    .contact-button:hover,
    .privacy-policy-button:hover,
    .option-button:hover,
    .restart-button:hover,
    .all-paths-button:hover,
    .next-button:hover,
    .close-button:hover {
      background-color: #050505;
      color: white;
    }
  }
  
  
  /* Option-specific button color */
  .privacy-policy-button {
    background-color: #4b6ada;
    margin-top: 3%;
    border-radius: 7px;
  }
  
  .privacy-policy-button:hover {
    background-color: #4563d1;
    transform: scale(1.05);
  }
  
  .restart-button,
  .next-button {
    margin-top: 20px;
    width: 100%;
  }
  
  .option-button:hover {
    animation: shake 0.5s;
  }
  
  /* Path Container */
  .path-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(145deg, rgba(0, 0, 0, 0.8), rgba(20, 30, 50, 0.9));
    color: rgb(233, 220, 220);
    border-radius: 10px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.7);
    width: 420px;
    height: 550px;
    transition: all 0.3s ease;
    position: relative;
    text-align: center;
  }
  
  #head{
    font-size: 40px;
    text-shadow: 2px 2px 12px rgba(119, 36, 187, 0.9);
  }
  #head2 {
    margin-bottom: 20px;
    text-shadow: 2px 2px 10px rgba(255, 0, 0, 0.8); /* Blood red text shadow */
    font-size: 30px;
  }
  
  /* Options container */
  .options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: fadeInOptions 1s ease-out;
  }
  
  /* Animations */
  .path-container.animate__animated {
    animation: fadeIn 1.5s ease-in-out forwards;
    opacity: 0;
  }
  
  @keyframes fadeInOptions {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-4px);
    }
    75% {
      transform: translateX(4px);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes glow {
    0% {
      text-shadow: 0 0 5px #ff3b3b, 0 0 10px #ff6b6b;
    }
    100% {
      text-shadow: 0 0 20px #ff3b3b, 0 0 30px #ff6b6b;
    }
  }
  
  /* Outcome box */
  .outcome {
    text-align: center;
    font-size: 18px;
    animation: glow 1.5s infinite alternate ease-in-out;
  }
  
  /* Contact Us overlay and popup */
  .contact-overlay,
  .privacy-policy-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
    z-index: 999;
  }
  
  .contact-popup,
  .privacy-policy-popup {
    background: #222;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
  }
  
  .hidden {
    display: none;
  }
  
  
  .read-about-button{
    background-color: #628ad4;
    border-radius: 10px;
    color: white;
    padding: 6px;
  }
  
  
  
  
  .policy-buttons {
    display: flex; /* Align buttons horizontally */
    justify-content: center; /* Center the buttons */
    margin-top: 10px; /* Add some spacing above the buttons */
  }
  
  .privacy-policy-button, .disclaimer-button {
    background-color: #4b6ada; /* You can adjust this color */
    margin: 0 5px; /* Space between buttons */
    border-radius: 7px;
    color: white;
    padding: 6px 12px; /* Increased padding for better appearance */
  }
  
  .privacy-policy-button:hover, .disclaimer-button:hover {
    background-color: #4563d1; /* Adjust hover color if needed */
    transform: scale(1.05);
  }
  
  /* Adjust modal styles to have white background */
  .contact-overlay,
  .privacy-policy-overlay,
  .disclaimer-overlay {
    background-color: rgba(255, 255, 255, 0.9); /* White background for modals */
    border-radius: 10px; /* Optional: for rounded corners */
    padding: 20px; /* Padding inside modal */
  }
  
  
  
  
  
  
  /* Tablet devices (up to 768px) */
  @media (max-width: 768px) {
    .path-container {
      width: 90%;
      height: 500px;
      padding: 15px;
    }
  
    #head {
      font-size: 30px;
    }
  
    #head2 {
      font-size: 25px;
    }
  
    .options {
      gap: 8px;
    }
  
    button {
      font-size: 18px;
      padding: 8px 16px;
    }
  }
  
  /* Mobile devices (up to 480px) */
  @media (max-width: 480px) {
    .path-container {
      width: 95%;
      height: 450px;
      padding: 12px;
    }
  
    #head {
      font-size: 25px;
    }
  
    #head2 {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    .options {
      gap: 6px;
    }
  
    button {
      font-size: 16px;
      padding: 6px 14px;
    }
  
    .contact-button,
    .privacy-policy-button,
    .read-about-button {
      font-size: 16px;
      padding: 6px 12px;
      margin-top: 5px;
    }
  
    .contact-overlay,
    .privacy-policy-popup {
      width: 90%;
      max-width: 400px;
      padding: 20px;
    }
  }
  
  /* Small mobile devices (up to 360px) */
  @media (max-width: 360px) {
    .path-container {
      width: 100%;
      height: 400px;
      padding: 10px;
    }
  
    #head {
      font-size: 22px;
    }
  
    #head2 {
      font-size: 18px;
    }
  
    button {
      font-size: 14px;
      padding: 5px 12px;
    }
  
    .contact-button,
    .privacy-policy-button,
    .read-about-button {
      font-size: 14px;
      padding: 5px 10px;
    }
  
    .contact-overlay,
    .privacy-policy-popup {
      width: 95%;
      max-width: 350px;
      padding: 15px;
    }
  }
  /* Base styling for SweetAlert2 popups */
.swal2-popup {
    border-radius: 15px !important;
    border: 2px solid #f4b400 !important;
    background: radial-gradient(circle, #1a1a1a, #000000) !important;
    color: #f4f4f4 !important;
    font-family: 'Arial', sans-serif;
  }
  
  /* Custom Title Styling */
  .custom-title {
    font-size: 2rem !important;
    font-weight: bold !important;
    color: #ffcc00 !important;
    text-shadow: 2px 2px #000;
  }
  
  /* Default Confirm Button */
  .custom-confirm-button {
    background-color: #007bff !important;
    color: white !important;
    font-size: 1.2rem !important;
    border-radius: 8px !important;
    padding: 10px 20px !important;
  }
  
  /* Custom for Dead (error) */
  .custom-popup-dead {
    background: linear-gradient(135deg, #4b0000, #1a0000) !important;
  }
  
  .custom-confirm-button-dead {
    background-color: #ff4e42 !important;
    color: #fff !important;
    font-size: 1.2rem !important;
    border-radius: 8px;
    border: 2px solid #660000;
  }
  
  /* Custom for Vampire outcome */
  .custom-popup-vampire {
    background: radial-gradient(circle, #2b1a3e, #000000) !important;
    border-color: #721c70 !important;
  }
  
  .custom-confirm-button-vampire {
    background-color: #8b008b !important;
    color: #ffb6c1 !important;
    font-size: 1.2rem !important;
    border-radius: 12px;
    border: 2px solid #fff;
  }
  
































  