.privacy-policy {
  position: relative;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 20px auto;
}

.close-button {
  display: block;
  width: 100%;
  background-color: dodgerblue;
  border: none;
  font-size: 20px;
  color: rgb(221, 195, 195);
  cursor: pointer;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
}

.close-button:hover {
  color: red;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .privacy-policy {
    padding: 20px;
    margin: 10px;
    max-width: 100%;
    overflow-y: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 16px;
  }
}
