

.background {
  height: 100vh;
  width: 100vw;
  background-image: url('./components/Background_2.jpeg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



/* Button Styles */
button {
  font-size: calc(10px + 1vmin);
  padding: 13px 15px;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Modal Styles */
.modal {
  max-width: 90vw;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  background: rgba(78, 72, 72, 0.8);
  color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

