.contact-us-container {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* text-align: left; */
    margin: 0 auto;
  }
  
  .contact-us-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  ul, ol {
    list-style: none;
  }
  
  .success-message {
    color: green;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .input-field, .textarea-field {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .input-field:focus, .textarea-field:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .textarea-field {
    resize: vertical;
    height: 100px;
  }
  
  .submit-button, .cancel-button {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    margin-top: 10px;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: black;
    margin-left: 10px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button:hover {
    background-color: #999;
  }
  