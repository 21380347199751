.introduction-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #fff;
    background-color: #2c2c2c;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .introduction-container h1 {
    font-size: 36px;
    text-align: center;
    color: #e74c3c;
    margin-bottom: 20px;
  }
  
  .introduction-container p {
    font-size: 18px;
    margin-bottom: 15px;
    color: #f0f0f0;
  }
  
  .introduction-container ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .introduction-container ul li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #d3d3d3;
  }
  
  .introduction-container ul li strong {
    color: #e74c3c;
  }
  
  .skip-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .skip-button:hover {
    background-color: #c0392b;
  }
  




  