/* General styles */
.questions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

/* General styles */
.questions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

#headAll {
    font-size: 54px; 
    color: rgb(243, 233, 233);
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px purple; /* Purple outline effect */
}

/* Path grid styles */
.path-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    gap: 15px; 
}

.path-button {
    background-color: black;
    color: white; 
    border: 2px solid purple; 
    border-radius: 8px; 
    padding: 15px 20px; 
    cursor: pointer;
    text-align: center;
    font-size: 20px; 
    flex: 1 1 calc(30% - 10px); 
    transition: background-color 0.3s, transform 0.3s;
    position: relative; 
    overflow: hidden; 
}

.path-button:hover {
    background-color: #333; 
    transform: scale(1.05); 
}

.path-button:focus {
    outline: none; 
}

.path-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%; 
    height: 300%;
    background: rgba(255, 0, 0, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0); 
    animation: horror-effect 1.5s infinite; 
    z-index: 0; 
}

.path-button:hover::before {
    transform: translate(-50%, -50%) scale(1); /* Scale to full size on hover */
}

/* Keyframes for horror animation */
@keyframes horror-effect {
    0%, 100% {
        opacity: 0; /* Fade out */
    }
    50% {
        opacity: 1; /* Fade in */
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .questions-container {
        padding: 15px;
    }

    h2 {
        font-size: 34px; /* Adjust heading font size for mobile */
        margin-bottom: 10px; /* Adjust margin below the heading */
        position: sticky; 
        top: 0;
        background: #3d3b3b; /* Background for better readability */
        padding: 10px 0;
        z-index: 10;
        border-radius: 7px;
    }

    .path-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        max-height: 70vh; /* Restrict height on mobile */
        overflow-y: auto; /* Make it scrollable */
        padding-top: 10px;
    }

    .path-button {
        flex: 1 1 calc(45% - 10px); /* 2 buttons per row on smaller screens */
        font-size: 18px; /* Slightly smaller font size */
    }
}

@media (max-width: 480px) {
    .path-button {
        flex: 1 1 calc(100% - 10px); /* 1 button per row on extra small screens */
        font-size: 16px; /* Further reduce font size */
    }

    h2 {
        font-size: 20px; /* Decrease heading font size for mobile */
        margin-bottom: 15px; /* Adjust margin below the heading */
    }
}


/* Path grid styles */
.path-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    gap: 15px; 
}

.path-button {
    background-color: black;
    color: white; 
    border: 2px solid purple; 
    border-radius: 8px; 
    padding: 15px 20px; 
    cursor: pointer;
    text-align: center;
    font-size: 20px; 
    flex: 1 1 calc(30% - 10px); 
    transition: background-color 0.3s, transform 0.3s;
    position: relative; 
    overflow: hidden; 
}

.path-button:hover {
    background-color: #333; 
    transform: scale(1.05); 
}

.path-button:focus {
    outline: none; 
}

.path-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%; 
    height: 300%;
    background: rgba(255, 0, 0, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0); 
    animation: horror-effect 1.5s infinite; 
    z-index: 0; 
}

.path-button:hover::before {
    transform: translate(-50%, -50%) scale(1); /* Scale to full size on hover */
}

/* Keyframes for horror animation */
@keyframes horror-effect {
    0%, 100% {
        opacity: 0; /* Fade out */
    }
    50% {
        opacity: 1; /* Fade in */
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .questions-container {
        padding: 15px;
    }

    h2 {
        font-size: 24px; /* Adjust heading font size for mobile */
        margin-bottom: 10px; /* Adjust margin below the heading */
        position: sticky; 
        top: 0;
        background: #000; /* Background for better readability */
        padding: 10px 0;
        z-index: 10;
    }

    .path-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        max-height: 70vh; /* Restrict height on mobile */
        overflow-y: auto; /* Make it scrollable */
        padding-top: 10px;
    }

    .path-button {
        flex: 1 1 calc(45% - 10px); /* 2 buttons per row on smaller screens */
        font-size: 18px; /* Slightly smaller font size */
    }
}

@media (max-width: 480px) {
    .path-button {
        flex: 1 1 calc(100% - 10px); /* 1 button per row on extra small screens */
        font-size: 16px; /* Further reduce font size */
    }

    h2 {
        font-size: 20px; /* Decrease heading font size for mobile */
        margin-bottom: 15px; /* Adjust margin below the heading */
    }
}
