@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');

.rain {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.rain.back-row {
  opacity: 0.6;
  z-index: 0;
}

/* Raindrop Styles */
.drop {
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 120px;
  pointer-events: none;
  animation: drop 0.25s linear infinite; /* Faster raindrop fall */
  background: rgba(173, 216, 230, 0.7); /* Light blue color for drops */
}

@keyframes drop {
  0% {
    transform: translateY(0vh);
  }
  75% {
    transform: translateY(90vh);
  }
  100% {
    transform: translateY(90vh);
  }
}

.stem {
  width: 1px;
  height: 60%;
  margin-left: 7px;
  background: linear-gradient(to bottom, rgba(173, 216, 230, 0.7), rgba(173, 216, 230, 0)); /* Light blue stem */
  animation: stem 0.25s linear infinite;
}

@keyframes stem {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Lightning Flash Effect */
.lightning-flash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;
  opacity: 0;
  animation: lightning 4s linear infinite; /* Slightly faster flashes */
}

@keyframes lightning {
  0%, 90%, 100% {
    opacity: 0;
  }
  91%, 93% {
    opacity: 1;
  }
  94% {
    opacity: 0;
  }
  96%, 98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
}

/* Fog Effect */
.fog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1); /* Light fog color */
  z-index: 5;
  animation: fogMove 30s linear infinite;
}

@keyframes fogMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}

/* Dracula Text with Violet Outline */
#dracula-text {
  font-family: "Creepster", sans-serif; /* Applying the spooky font */
  font-size: 170px; /* Increased font size for visibility */
  font-weight: 300; /* Decreased font weight */
  color: black; /* Set primary text color to black */
  position: relative;
  text-shadow: 
    2px 2px 15px rgba(0, 0, 0, 0.9), /* Dark shadow for better contrast */
    0 0 25px rgba(139, 0, 0, 0.7); /* Optional glow effect */
  z-index: 2;
  display: flex;
  gap: 8px; /* Space between letters */
}

/* Outline Effect for Text */
#dracula-text .letter {
  position: relative;
  color: black; /* Black color for each letter */
  display: inline-block;
  overflow: visible;
  text-shadow: 
    0 0 5px violet, /* Violet outline */
    0 0 10px violet,
    0 0 15px violet; /* Increase shadow layers for a thicker outline */
}

/* Drip Style */
#dracula-text .drip {
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 4px;
  height: 10px;
  background-color: #8b0000;
  border-radius: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: drip 2.5s infinite;
}

/* Staggered animation delays for each drip */
#dracula-text .letter:nth-child(odd) .drip { animation-delay: 0.2s; }
#dracula-text .letter:nth-child(even) .drip { animation-delay: 0.6s; }

/* Blood Drip Animation */
@keyframes drip {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
    height: 10px;
  }
  50% {
    opacity: 1;
    transform: translate(-50%, 15px);
    height: 20px;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 25px);
    height: 0;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  #dracula-text {
    font-size: 58px; /* Smaller font size for tablets */
  }
}

@media (max-width: 480px) {
  #dracula-text {
    font-size: 50px; /* Smaller font size for phones */
  }
}

