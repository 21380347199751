/* Overlay for modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Dark black, almost opaque */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px; /* Adds space on smaller screens */
}

/* Modal container */
.modal-content.enhanced-modal {
  background: radial-gradient(circle, #1a001f, #000); /* Deep violet to black */
  border: 2px solid #ff0000; /* Red border for ghostly effect */
  border-radius: 15px;
  padding: 25px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  color: #ffffff; /* White text */
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.6), 0 0 30px rgba(75, 0, 130, 0.8); /* Glowing red/violet shadow */
}

/* Title styles */
.modal-title {
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(135deg, #ff0000, #4b0082); /* Red to violet gradient */
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.8); /* Red glowing text */
}

/* Description styles */
.modal-description {
  font-size: 1.1rem;
  margin-top: 15px;
  color: #f5f5f5; /* Light gray text for readability */
  padding: 0 15px;
}

/* Challenge button */
.challenge-button {
  margin-top: 20px;
  padding: 12px 25px;
  background: linear-gradient(135deg, #800020, #4b0082); /* Dark red to violet */
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: bold;
  border: 2px solid #ffffff; /* White border */
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.challenge-button:hover {
  background: linear-gradient(135deg, #4b0082, #ff0000); /* Reverse ghost gradient */
  box-shadow: 0 0 20px #ff0000, 0 0 25px #4b0082; /* Eerie glowing effect */
  transform: scale(1.1);
}

.challenge-button:active {
  background-color: #500010; /* Darker ghostly red */
  transform: scale(1.05);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .modal-content.enhanced-modal {
    padding: 20px;
    max-width: 90%;
  }
  
  .modal-title {
    font-size: 1.8rem;
    padding: 8px;
  }

  .modal-description {
    font-size: 1rem;
  }

  .challenge-button {
    font-size: 1.1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .modal-title {
    font-size: 1.6rem;
  }

  .modal-description {
    font-size: 0.9rem;
  }

  .challenge-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
